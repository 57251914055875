export interface LandingPage {
	name: string;
	value: string;
	route: string;
}

export enum LandingPageValue {
	Tga = 'tga',
	Dashboard = 'dashboard',
	CoverageDashboard = 'dashboard_v2',
	QualityAnalytics = 'quality_analytics',
	TestOptimization = 'test_optimization',
}

export const LandingPages: Record<string, LandingPage> = {
	[LandingPageValue.Tga]: {
		name: 'TGA',
		value: LandingPageValue.Tga,
		route: '/tga'
	},
	[LandingPageValue.Dashboard]: {
		name: 'Dashboard',
		value: LandingPageValue.Dashboard,
		route: '/dashboard/v1'
	},
	[LandingPageValue.CoverageDashboard]: {
		name: 'Coverage Dashboard',
		value: LandingPageValue.CoverageDashboard,
		route: '/dashboard/v2'
	},
	[LandingPageValue.QualityAnalytics]: {
		name: 'Quality Analytics',
		value: LandingPageValue.QualityAnalytics,
		route: '/quality-analytics'
	},
	[LandingPageValue.TestOptimization]: {
		name: 'Test Optimization',
		value: LandingPageValue.TestOptimization,
		route: '/tia/v3'
	}
};
